import { Container } from "@mui/material";
import { Contributions as MyContributions } from "../Activities/contributions";
import { ROUTES } from "@/config";
import { useCalculatePaddingTop } from "@/hooks";
import { SubnavigationBar } from "@/components";
import CreateIcon from "@mui/icons-material/Create";

export const Contributions = () => {
  const paddingTop = useCalculatePaddingTop();

  return (
    <>
      <SubnavigationBar
        breadcrumbs={[
          {
            label: "Contributions",
            href: ROUTES.contributions,
            icon: <CreateIcon />,
          },
        ]}
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pt: paddingTop,
        }}
      >
        <MyContributions />
      </Container>
    </>
  );
};
