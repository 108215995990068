import React, { useState } from "react";
import {
  Box,
  Typography,
  Popper,
  alpha,
  styled,
  TypographyProps,
} from "@mui/material";
import { Reaction } from "@/interface";
import { ReactionType } from "@/enums";
import { useUserState } from "@/state";
import { Emoji } from "./Emoji";

type User = {
  fullName?: string;
}

type Props = {
  reactions: Reaction[];
  onReact: (reaction: ReactionType) => void;
};

const StyledReactionBox = styled(Box)<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0.2, 0.8),
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(4),
    cursor: "pointer",
    ...(ownerState.active && {
      borderColor: theme.palette.primary.light,
      background: alpha(theme.palette.primary.main, 0.08),
    }),
    ":hover": {
      background: ownerState?.active
        ? alpha(theme.palette.primary.main, 0.2)
        : theme.palette.action.hover,
    },
  })
);

const StyledPopoverBox = styled(Box)({
  backgroundColor: "black",
  color: "white",
  padding: "16px",
  borderRadius: "8px",
  width: "200px",
  height: "auto",
  maxHeight: "300px",
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  position: "relative",
});

const BottomText = styled(Typography)<TypographyProps>({
  fontSize: "12px",
});


const transformName = (fullName: string): string => {
  const MAX_NAME_LENGTH = 20;
  let name = fullName;


  if (name.includes(",")) {
    const parts = name.split(",");
    if (parts.length === 2) {
      name = `${parts[1].trim()} ${parts[0].trim()}`;
    }
  }


  if (name.length > MAX_NAME_LENGTH) {
    return name.slice(0, MAX_NAME_LENGTH) + "...";
  }

  return name;
}


type ReactionUsersTooltipProps = {
  reaction: ReactionType;
  getReactionUsers: (reactionType: ReactionType) => User[];
};

const ReactionUsersTooltip = ({
  reaction,
  getReactionUsers,
}: ReactionUsersTooltipProps) => {
  const users = getReactionUsers(reaction);


  const displayCount = users.length > 3 ? 3 : users.length;
  const displayedUsers = users.slice(0, displayCount);
  const remainingCount = users.length - displayCount;


  const names = displayedUsers.map((user) =>
    user.fullName ? transformName(user.fullName) : ""
  );


  const joinNames = (namesArray: string[]): string => {
    if (namesArray.length === 1) return namesArray[0];
    if (namesArray.length === 2) {
      return `${namesArray[0]} and ${namesArray[1]}`;
    }

    return (
      namesArray.slice(0, namesArray.length - 1).join(", ") +
      " and " +
      namesArray[namesArray.length - 1]
    );
  };

  let displayText = joinNames(names);


  if (remainingCount > 0) {
    displayText += ` and ${remainingCount} others`;
  }

  return <BottomText component="span">{displayText}</BottomText>;
};

export const ReactionsRenderer = ({ reactions, onReact }: Props) => {
  const { userData } = useUserState();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hoveredReaction, setHoveredReaction] = useState<ReactionType | null>(null);

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    reactionType: ReactionType
  ) => {
    setAnchorEl(event.currentTarget);
    setHoveredReaction(reactionType);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setHoveredReaction(null);
  };

  const open = Boolean(anchorEl);

  const getReactionUsers = (reactionType: ReactionType) => {
    const reaction = reactions.find((r) => r.reactionType === reactionType);
    if (!reaction) return [];
    return Array.from(reaction.users.values());
  };

  return (
    <>
      {reactions.map(({ reactionType, users }) => {
        const isActive = users.has(userData?.id ?? "");
        return (
          <StyledReactionBox
            key={reactionType}
            ownerState={{ active: isActive }}
            onClick={() => onReact?.(reactionType)}
            onMouseEnter={(e) => handleMouseEnter(e, reactionType)}
            onMouseLeave={handleMouseLeave}
          >
            <Emoji emojiType={reactionType} sx={{ fontSize: 18 }} />
            <Typography variant="subtitle2">{users.size}</Typography>
          </StyledReactionBox>
        );
      })}

      <Popper open={open} anchorEl={anchorEl} placement="bottom-start" role="tooltip">
        {hoveredReaction && (
          <StyledPopoverBox onMouseLeave={handleMouseLeave}>
            <ReactionUsersTooltip
              reaction={hoveredReaction}
              getReactionUsers={getReactionUsers}
            />
          </StyledPopoverBox>
        )}
      </Popper>
    </>
  );
};
