import { useState } from "react";
import { ContributionEditor as ShoutoutEditor } from "@/pages/common/editor/ContributionEditor";
import { Receivers } from "../common/Receivers";
import { useShoutsState } from "@/state";
import { CreateShoutButton } from "./CreateShoutButton";
import { Visibility } from "../common/Visibility";
import GifPicker from "./GifPicker";
import { Button, styled } from "@mui/material";
import { useGifEditor } from "../../../../hooks/useGifEditor";

type Props = {
  onMutate: () => void;
};

const AddGifButton = styled(Button)({
  marginRight: "20px",
});

export const ShoutoutsEditor = ({ onMutate }: Props) => {
  const { receivers, isPublic, setState } = useShoutsState();
  const [gifPickerOpen, setGifPickerOpen] = useState(false);
  const {
    editorContent,
    gifUrl,
    handleGifSelect,
    handleRemoveGif,
    handleEditorChange,
  } = useGifEditor();

  return (
    <>
      <ShoutoutEditor
        element={
          <Receivers
            receivers={receivers}
            onChange={(receivers) => setState({ receivers })}
          />
        }
        initialContent={editorContent}
        onEditorChange={handleEditorChange}
        caption="Post shout-out for"
        placeholder="Shout-out and recognise your peers"
        showFooter={false}
        sx={{ background: (theme) => theme.palette.common.white }}
        selectedGif={gifUrl}
        onRemoveGif={handleRemoveGif}
      >
        <Visibility
          isPublic={isPublic}
          onSelect={(isPublic) => setState({ isPublic })}
        />
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div>
            <AddGifButton
              variant="outlined"
              onClick={() => setGifPickerOpen(true)}
            >
              Add GIF
            </AddGifButton>
            <CreateShoutButton onMutate={onMutate} />{" "}
          </div>
        </div>
      </ShoutoutEditor>
      <GifPicker
        open={gifPickerOpen}
        onClose={() => setGifPickerOpen(false)}
        onSelect={handleGifSelect}
      />
    </>
  );
};
