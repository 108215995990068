import { Container } from "@mui/material";
import { useShouts } from "@/hooks/api";
import { useCalculatePaddingTop } from "@/hooks";
import { useShoutsState, useUserState } from "@/state";
import { useEffect } from "react";
import { ROUTES } from "@/config";
import { ScrollToTopButton, SubnavigationBar } from "@/components";
import { BirthdayCelebration } from "@/components/BirthdayCelebration";
import { ShoutoutsEditor, Header, ShoutoutsList } from "../common/shoutouts";
import { Filters } from "../common/filters/Filters";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";

const Shoutouts = () => {
  const paddingTop = useCalculatePaddingTop();
  const { userData } = useUserState();
  const {
    shoutouts,
    isLoading,
    total,
    loadMore,
    refresh,
    deleteShoutout,
    editShoutout,
    addReaction,
    deleteReaction,
  } = useShouts();
  const { shoutout, setState } = useShoutsState();

  useEffect(() => {
    if (shoutout) {
      refresh();
      setState({ shoutout: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shoutout]);

  return (
    <>
      <SubnavigationBar
        breadcrumbs={[
          {
            label: "Shout-outs",
            href: ROUTES.root,
            icon: <CelebrationRoundedIcon />,
          },
        ]}
      />
      <BirthdayCelebration birthDate={userData?.birthDate} />
      <Container
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          pt: paddingTop,
        }}
      >
        <Header total={total} />
        <ShoutoutsEditor onMutate={refresh} />
        <Filters
          keywork={false}
          category={false}
          search={false}
          seekingContributorsToggle={false}
        />
        <ShoutoutsList
          shouts={shoutouts}
          hasReactions
          isLoading={isLoading}
          loadMore={loadMore}
          onDelete={deleteShoutout}
          onEdit={editShoutout}
          onAddReaction={addReaction}
          onDeleteReaction={deleteReaction}
        />
      </Container>
      <ScrollToTopButton />
    </>
  );
};

export { Shoutouts };
