import { useState, useEffect } from "react";
import { useShoutsState } from "@/state";

export const useGifEditor = () => {
  const { content, gifUrl, setState } = useShoutsState();
  const [editorContent, setEditorContent] = useState("");

  useEffect(() => {
    if (content) {
      const gifMatch = content.match(/<img src="([^"]+)" alt="GIF" \/>/);
      if (gifMatch) {
        setState({ gifUrl: gifMatch[1] });
        setEditorContent(content.replace(/<img src="[^"]+" alt="GIF" \/>/, ""));
      } else {
        setEditorContent(content);
        setState({ gifUrl: null });
      }
    } else {
      setEditorContent("");
      setState({ gifUrl: null });
    }
  }, [content, setState]);

  const handleGifSelect = (url: string) => {
    setState({ gifUrl: url });
    const updatedContent = `<img src="${url}" alt="GIF" />${editorContent}`;
    setState({ content: updatedContent });
    return updatedContent;
  };

  const handleRemoveGif = () => {
    setState({ gifUrl: null });
    setState({ content: editorContent });
  };

  const handleEditorChange = (html: string) => {
    setEditorContent(html);
    const updatedContent = gifUrl
      ? `<img src="${gifUrl}" alt="GIF" />${html}`
      : html;
    setState({ content: updatedContent });
  };

  return {
    editorContent,
    gifUrl,
    handleGifSelect,
    handleRemoveGif,
    handleEditorChange,
  };
};
