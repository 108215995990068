import { useState } from "react";
import {
  Divider,
  Fab,
  Menu,
  MenuItem,
  ListItemIcon,
  Stack,
  createTheme,
  ThemeProvider,
  keyframes,
} from "@mui/material";
import QuestionMarkRoundedIcon from "@mui/icons-material/QuestionMarkRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import GppMaybeRoundedIcon from "@mui/icons-material/GppMaybeRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import { FAQLink, supportLink, privacyPolicyLink } from "@/config";
import { ensureHttpPrefix } from "@/utils";
import DOMPurify from "dompurify";
import { useUIActionsState } from "@/state";
import GettingStartedWizard from "../GettingStartedWizard";
import { wizardSteps } from "@/config/wizardSteps";

const MAX_STEP = wizardSteps.length - 1;

// Function to handle the click and navigate to the link
const handleLinkClick = (link: string) => () => {
  const sanitizedLink = ensureHttpPrefix(DOMPurify.sanitize(link));
  window.open(sanitizedLink, "_blank");
};

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0.4);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(25, 118, 210, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(25, 118, 210, 0);
  }
`;

export const FloatingHelpButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [wizardOpen, setWizardOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { multipleFloatingButtonsInViewport, isSnackbarOpen } =
    useUIActionsState();

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleWizardOpen = () => {
    setCurrentStep(0);
    setWizardOpen(true);
    onClose();
  };

  const handleWizardClose = () => {
    setWizardOpen(false);
    setCurrentStep(0);
  };

  const handleUpdateStep = (step: number) => {
    if (step >= 0 && step <= MAX_STEP) {
      setCurrentStep(step);
    }
  };

  const openMenu = Boolean(anchorEl);
  return (
    <>
      <Fab
        size="small"
        color="primary"
        aria-label="help"
        onClick={onClick}
        sx={(theme) => ({
          position: "fixed",
          right: theme.spacing(2),
          bottom: isSnackbarOpen?.isOpen
            ? theme.spacing(11)
            : multipleFloatingButtonsInViewport
              ? theme.spacing(10)
              : theme.spacing(2),
          transition: "all 0.3s ease-in-out",
          animation: `${pulseAnimation} 2s infinite`,
          background: "linear-gradient(45deg, #1976d2 30%, #2196f3 90%)",
          "&:hover": {
            background: "linear-gradient(45deg, #1565c0 30%, #1976d2 90%)",
            transform: "scale(1.1)",
            boxShadow: "0 6px 20px rgba(25, 118, 210, 0.3)",
          },
          boxShadow: "0 4px 12px rgba(25, 118, 210, 0.2)",
          zIndex: 1000,
        })}
      >
        {openMenu ? (
          <CloseRoundedIcon sx={{ fontSize: 20 }} />
        ) : (
          <QuestionMarkRoundedIcon sx={{ fontSize: 20 }} />
        )}
      </Fab>
      <ThemeProvider
        theme={(currentTheme) =>
          createTheme({ ...currentTheme, palette: { mode: "dark" } })
        }
      >
        <Menu
          anchorEl={anchorEl}
          id="profile-menu"
          data-testid="profile-menu"
          open={openMenu}
          onClose={onClose}
          onClick={onClose}
          sx={(theme) => ({
            "& .MuiPaper-root": {
              backgroundColor: "background.default",
              boxShadow: theme.shadows[6],
            },
            marginTop: "-8px",
          })}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Stack>
            <MenuItem onClick={handleWizardOpen}>
              <ListItemIcon>
                <SchoolRoundedIcon />
              </ListItemIcon>
              Getting Started
            </MenuItem>
            <MenuItem onClick={handleLinkClick(FAQLink)}>
              <ListItemIcon>
                <HelpRoundedIcon />
              </ListItemIcon>
              FAQs
            </MenuItem>
            <MenuItem onClick={handleLinkClick(supportLink)}>
              <ListItemIcon>
                <SupportAgentRoundedIcon />
              </ListItemIcon>
              Contact support
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLinkClick(privacyPolicyLink)}>
              <ListItemIcon>
                <GppMaybeRoundedIcon />
              </ListItemIcon>
              Privacy Policy
            </MenuItem>
          </Stack>
        </Menu>
      </ThemeProvider>

      {wizardOpen && (
        <GettingStartedWizard
          open={wizardOpen}
          onClose={handleWizardClose}
          currentStep={currentStep}
          updateStep={handleUpdateStep}
        />
      )}
    </>
  );
};
