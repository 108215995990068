import { API_ENDPOINTS, fetcher } from "@/config";
import { Contributor } from "@/interface";
import { useUserState } from "@/state";
import { useSearch } from "@/hooks";
import { toContributorsQuery, toKeys } from "@/utils";
import { useEffect, useState } from "react";
import useSWR from "swr";

export const useContributors = () => {
  const { authToken } = useUserState();
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const { regions, offices, categories, keyword } = useSearch();

  useEffect(() => {
    const query = toContributorsQuery(
      toKeys(categories),
      toKeys(regions),
      toKeys(offices),
      keyword
    );
    setSearchQuery(query);
  }, [regions, offices, categories, keyword]);

  const { data, isLoading } = useSWR<Contributor[]>(
    searchQuery ?? `${API_ENDPOINTS.contributions}/users`,
    (url: string) => fetcher(url, { authToken }),
    { revalidateOnFocus: false }
  );

  return { contributors: data, isLoading };
};
