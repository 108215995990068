import {
  Button,
  Container,
  Dialog,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Header } from "../common/Header";
import { useContributors } from "@/hooks/api";
import { useNavigate } from "react-router-dom";
import { useCalculatePaddingTop } from "@/hooks";
import { ContributorsTable } from "../common/table/ContributorsTable";
import { useEffect, useState } from "react";
import { useBulkSparxState, useSparxState, useUserState } from "@/state";
import { Drawer } from "../common/drawer/Drawer";
import { DistributionModal } from "../common/distribution/DistributionModal";
import { SuccessDialog } from "../../common/SuccessDialog";
import { toContributors } from "@/utils";
import { Filters } from "@/pages/common/filters/Filters";
import { Balance, SubnavigationBar } from "@/components";
import { ROUTES } from "@/config";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { updateSelection } from "../common/table/utils.ts";
import { ArrowForwardRounded } from "@mui/icons-material";
import BudgetCardViz from "@/assets/BudgetCardViz.svg";

export const ReviewContributions = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const paddingTop = useCalculatePaddingTop();
  const [activeButton, setActiveButton] = useState<boolean>(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const { contributors, isLoading } = useContributors();
  const { userData } = useUserState();
  const { setActivity, setBulkReceivers } = useSparxState();
  const {
    setState,
    selectedContributors,
    sparx,
    balance,
    setOpenDistributeModal,
  } = useBulkSparxState();
  const [usersSparx, setUsersSparx] = useState<Record<string, number>>({});

  useEffect(() => {
    setState({
      contributors: toContributors(contributors, usersSparx).filter(
        (contributor) => contributor.user.id !== userData?.id
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributors, userData]);

  const onClick = () => {
    setActivity();
    setBulkReceivers(selectedContributors);
    setOpenDistributeModal();
  };

  const onClose = () => {
    setOpenSuccessDialog(false);
    navigate(ROUTES.sparx);
  };

  const onSelectUser = (userId: string) => {
    const selectedIndex = selected.indexOf(userId);
    const newSelected = updateSelection(selected, selectedIndex, userId);
    setSelected(newSelected);
  };

  const setUserSparxAmount = (userId: string, value: string | number) => {
    const numericValue = Number(value);
    const sparxAmount = usersSparx[userId];

    if (isNaN(numericValue) || numericValue < 0) {
      console.error("Invalid Sparx amount:", value);
      return;
    }

    setUsersSparx({ ...usersSparx, [userId]: numericValue });

    const currentSparxAmount = sparxAmount !== undefined ? sparxAmount : 0;

    if (currentSparxAmount > 0 !== numericValue > 0) {
      onSelectUser(userId);
    }
  };

  return (
    <>
      <SubnavigationBar
        breadcrumbs={[
          {
            label: "Sparx",
            href: ROUTES.sparx,
            icon: <AutoAwesomeRoundedIcon />,
          },
          {
            label: "Review & Reward Contributions",
            href: ROUTES.review_sparx_contributions,
          },
        ]}
        tabs={
          <Balance
            sparx={sparx}
            balance={balance}
            sx={{ width: "fit-content" }}
          />
        }
        actions={
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardRounded />}
            onClick={onClick}
            disabled={
              !activeButton ||
              !selectedContributors?.length ||
              selectedContributors.some((contributor) => contributor.error)
            }
          >
            Send Sparx
          </Button>
        }
      />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          pt: paddingTop,
        }}
      >
        <Header />
        <ContributorsTable
          isLoading={isLoading}
          setActiveButton={(bool: boolean) => setActiveButton(bool)}
          selected={selected}
          usersSparx={usersSparx}
          setUserSparxAmount={setUserSparxAmount}
        >
          <Filters
            multipleCategories={false}
            sx={{ width: "100%" }}
            seekingContributorsToggle={false}
          />
        </ContributorsTable>
      </Container>
      <Drawer setUserSparxAmount={setUserSparxAmount} />
      <DistributionModal onSuccess={() => setOpenSuccessDialog(true)} />
      <Dialog
        maxWidth="sm"
        open={openSuccessDialog}
        fullScreen={fullScreen}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paperFullScreen": {
            borderRadius: 0,
          },
        }}
      >
        <SuccessDialog
          banner={BudgetCardViz}
          bannerSubtitle1={"Your Sparx are on their way."}
          bannerSubtitle2={
            "You will brighten someone's day once they unbox them!"
          }
          onClose={onClose}
        />
      </Dialog>
    </>
  );
};
