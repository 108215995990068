import { styled, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const GifPreviewContainer = styled(Box)({
  position: "relative",
  maxWidth: "200px",
  marginTop: "10px",
  "& img": {
    width: "100%",
    height: "auto",
    borderRadius: "4px",
  },
});

const RemoveGifButton = styled(IconButton)({
  position: "absolute",
  top: "-10px",
  right: "-10px",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  color: "white",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
});

type GifPreviewProps = {
  src: string;
  onRemove: () => void;
};

export const GifPreview = ({ src, onRemove }: GifPreviewProps) => (
  <GifPreviewContainer>
    <img src={src} alt="Selected GIF" />
    <RemoveGifButton size="small" onClick={onRemove}>
      <CloseIcon fontSize="small" />
    </RemoveGifButton>
  </GifPreviewContainer>
);
