import React, { useState } from "react";
import { GiphyFetch } from "@giphy/js-fetch-api";
import { Grid } from "@giphy/react-components";
import { Dialog, DialogContent, DialogTitle, IconButton, Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const GIPHY_API = import.meta.env.VITE_GIPHY_API;

const giphyFetch = new GiphyFetch(GIPHY_API);

type GifPickerProps = {
    onSelect: (url: string) => void;
    open: boolean;
    onClose: () => void;
};

const GifPicker: React.FC<GifPickerProps> = ({ onSelect, open, onClose }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const fetchGifs = async (offset: number) => {
        const result = await giphyFetch.search(searchTerm || "trending", {
            offset,
            limit: 10,
            rating: "g"
        });
        return result;
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Select a GIF
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: "absolute", right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ height: "500px", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                <input
                    type="text"
                    placeholder="Search GIFs..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{
                        width: "100%",
                        padding: "8px",
                        marginBottom: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                />

                <Box sx={{ flexGrow: 1, overflowY: "auto", position: "relative" }}>
                    <Grid
                        key={searchTerm}
                        fetchGifs={fetchGifs}
                        width={500}
                        columns={3}
                        gutter={6}
                        onGifClick={(gif, e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onSelect(gif.images.original.url);
                            onClose();
                        }}
                    />

                </Box>

                <Box sx={{ textAlign: "center", padding: "8px 0" }}>
                    <Typography variant="body2" color="textSecondary">
                        Powered by
                    </Typography>
                    <a href="https://giphy.com" target="_blank" rel="noopener noreferrer">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/8/82/Giphy-logo.svg"
                            alt="Giphy Logo"
                            style={{ height: "20px", marginLeft: "5px" }}
                        />
                    </a>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default GifPicker;
