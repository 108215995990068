import { ROUTES } from "./index";
import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import MonitorHeartRoundedIcon from "@mui/icons-material/MonitorHeartRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { SxProps, Theme } from "@mui/material";

const iconStyle: SxProps<Theme> = { fontSize: 40, color: "primary.main" };

export interface WizardStep {
  title: string;
  subtitle: string;
  content: string;
  icon?: JSX.Element;
  features?: string[];
  chips?: string[];
  actionLabel?: string;
  route?: string;
  shortTitle?: string;
}

export const wizardSteps: WizardStep[] = [
  {
    title: "Welcome to Xconnect",
    subtitle: "Bringing our community to life",
    shortTitle: "Welcome",
    content:
      "Xconnect is your gateway to community, recognition, and collaboration across BCG X — built by Xers, for Xers, to celebrate what makes us stronger together.",
    icon: <GroupsIcon sx={iconStyle} />,
    features: [
      "Create lasting impact in our community",
      "Connect with fellow innovators",
      "Celebrate team achievements",
      "Build an inclusive environment",
    ],
  },
  {
    title: "Discover Initiatives",
    subtitle: "Where Xers shape our culture, together",
    shortTitle: "Initiatives",
    content:
      "Explore activities to get involved in, or create your own initiative to gather support. Initiatives are activities outside of casework or the normal scope of your role that help to build our community.",
    icon: <LightbulbRoundedIcon sx={iconStyle} />,
    features: [
      "Launch Community-building projects",
      "Join thriving communities",
      "Drive meaningful change",
      "Shape BCG X's future",
    ],
    chips: [
      "Chapter",
      "Diversity & Inclusion",
      "Marketing",
      "People Development",
      "Practice Area",
      "Recruitment",
    ],
    actionLabel: "Explore Initiatives",
    route: ROUTES.initiatives,
  },
  {
    title: "Give Shout-outs",
    subtitle: "Celebrate your peers",
    shortTitle: "Shout-outs",
    content:
      "Recognize the exceptional individuals who make BCG X extraordinary. Your shout-outs celebrate achievements, inspire others, and strengthen our culture of appreciation. You can give a shout-out to anyone in X, regardless of their level.",
    icon: <CelebrationRoundedIcon sx={iconStyle} />,
    features: [
      "Recognize great contributions",
      "Boost team morale",
      "Connect global teams",
      "Build a culture of appreciation",
    ],
    actionLabel: "Give Recognition",
    route: ROUTES.root,
  },
  {
    title: "Log Contributions",
    subtitle: "Give visibility and track your impact",
    shortTitle: "Contributions",
    content:
      "Document your journey of impact at BCG X - keep a log of your contributions for your PIF, and give visibility to your efforts. Contributions are only visible to X leadership (not all staff), and may help you get Sparx for activities that might not be on their radar.",
    icon: <MonitorHeartRoundedIcon sx={iconStyle} />,
    features: [],
    chips: [],
    actionLabel: "Log Activity",
    route: ROUTES.my_activity,
  },
  {
    title: "Send Sparx",
    subtitle: "Ignite Recognition",
    shortTitle: "Sparx",
    content:
      "Make someone's day brighter with Sparx! Use your quarterly tokens to recognize and reward the outstanding contributions that make BCG X an exceptional place to work. You can give Sparx to anybody below Partner / SD level, but they will only become visible to recipients at the end of each quarter. At year-end, top Sparx receivers will receive a bonus top-up.",
    icon: <AutoAwesomeRoundedIcon sx={iconStyle} />,
    features: [
      "Reward excellence",
      "Inspire greatness",
      "Strengthen connections",
      "Foster recognition",
    ],
    actionLabel: "Send Recognition",
    route: ROUTES.sparx,
  },
  {
    title: "Need Help?",
    subtitle: "We're Here for You",
    shortTitle: "Help",
    content:
      "Have questions or need assistance? Our comprehensive help resources and support team are here to guide you through your Xconnect journey.",
    icon: <HelpOutlineIcon sx={iconStyle} />,
    features: [
      "Browse our FAQ section",
      "Access help documentation",
      "Contact support team",
      "Join community forums",
    ],
    chips: [],
  },
];
