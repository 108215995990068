import { useEffect, useState, useRef, useMemo } from "react";
import { Box, keyframes, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const float = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    filter: brightness(1);
  }
  25% {
    transform: translate(15px, -25px) rotate(5deg) scale(1.02);
    filter: brightness(1.05);
  }
  50% {
    transform: translate(0, -40px) rotate(-2deg) scale(1);
    filter: brightness(1.1);
  }
  75% {
    transform: translate(-15px, -25px) rotate(-5deg) scale(0.98);
    filter: brightness(1.05);
  }
  100% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    filter: brightness(1);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
`;

const sway = keyframes`
  0% {
    transform: rotate(0deg) translateX(0) scaleY(1);
  }
  33% {
    transform: rotate(15deg) translateX(5px) scaleY(1.03);
  }
  66% {
    transform: rotate(-15deg) translateX(-5px) scaleY(1.03);
  }
  100% {
    transform: rotate(0deg) translateX(0) scaleY(1);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const starFloat = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 0.4;
  }
  50% {
    transform: translate(var(--dx), var(--dy)) rotate(180deg) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translate(0, 0) rotate(360deg) scale(1);
    opacity: 0.4;
  }
`;

const glow = keyframes`
  0%, 100% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
`;

const pop = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.5;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
`;

const sparkle = keyframes`
  0% {
    filter: brightness(1) scale(1);
  }
  50% {
    filter: brightness(1.5) scale(1.2);
  }
  100% {
    filter: brightness(1) scale(1);
  }
`;

const flyUp = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, -200vh) rotate(360deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpLeft = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-100px, -200vh) rotate(360deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpRight = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(100px, -200vh) rotate(-360deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpZigzag = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  25% {
    transform: translate(50px, -50vh) rotate(90deg) scale(0.9);
  }
  50% {
    transform: translate(-50px, -100vh) rotate(180deg) scale(0.8);
  }
  75% {
    transform: translate(50px, -150vh) rotate(270deg) scale(0.7);
  }
  100% {
    transform: translate(0, -200vh) rotate(360deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpSpiral = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, -200vh) rotate(720deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpGentle = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(40px, -200vh) rotate(360deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpWave = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, -200vh) rotate(180deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpBounce = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, -200vh) rotate(360deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpDance = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(0, -200vh) rotate(360deg) scale(0.5);
    opacity: 0;
  }
`;

const flyUpFloat = keyframes`
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(20px, -200vh) rotate(180deg) scale(0.5);
    opacity: 0;
  }
`;

const Balloon = styled(Box)(() => ({
  position: "fixed",
  width: "65px",
  height: "85px",
  borderRadius: "50% 50% 50% 50% / 60% 60% 40% 40%",
  animation: `${float} 12s ease-in-out infinite`,
  background:
    "linear-gradient(135deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0) 70%)",
  boxShadow: "inset -10px -10px 20px rgba(0,0,0,0.2)",
  willChange: "transform",
  transform: "translate3d(0,0,0)",
  backfaceVisibility: "hidden",
  perspective: 1000,
  "&::before": {
    content: '""',
    position: "absolute",
    bottom: "-12px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "16px",
    height: "16px",
    background: "inherit",
    borderRadius: "0 0 12px 12px",
    filter: "brightness(0.8)",
    clipPath: "polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)",
  },
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-35px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "2px",
    height: "40px",
    background: "rgba(0,0,0,0.3)",
    animation: `${sway} 3s ease-in-out infinite`,
    transformOrigin: "top",
    willChange: "transform",
    boxShadow: "1px 1px 1px rgba(0,0,0,0.2)",
  },
}));

const Star = styled(Box)(() => ({
  position: "fixed",
  width: "20px",
  height: "20px",
  animation: `${starFloat} 4s ease-in-out infinite`,
  willChange: "transform",
  "&::before, &::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    background: "currentColor",
    clipPath:
      "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
  },
}));

const MessageContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "15px",
  left: "50%",
  transform: "translate(-50%, 0)",
  width: "400px",
  background: "rgba(255, 255, 255, 0.95)",
  borderRadius: "16px",
  padding: theme.spacing(1.5, 3),
  backdropFilter: "blur(8px)",
  boxShadow: "0 8px 32px rgba(0,0,0,0.1)",
  border: "1px solid rgba(255,255,255,0.3)",
  animation: `${fadeIn} 0.5s ease-out`,
  zIndex: 10000,
  pointerEvents: "none",
  userSelect: "none",
  "&.fade-out": {
    animation: `${fadeOut} 0.5s ease-out forwards`,
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-20px",
    left: "-20px",
    right: "-20px",
    bottom: "-20px",
    background:
      "radial-gradient(circle, rgba(255,223,87,0.2) 0%, rgba(255,223,87,0) 70%)",
    borderRadius: "30px",
    animation: `${glow} 2s ease-in-out infinite`,
    zIndex: -1,
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

const Message = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(
    90deg,
    ${theme.palette.primary.main},
    ${theme.palette.secondary.main},
    ${theme.palette.primary.main}
  )`,
  backgroundSize: "200% auto",
  animation: `${shimmer} 4s linear infinite`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  fontWeight: 800,
  letterSpacing: "0.5px",
  textShadow: "0 2px 4px rgba(0,0,0,0.1)",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "inherit",
    filter: "blur(8px)",
    opacity: 0.3,
    zIndex: -1,
  },
}));

const SubMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textAlign: "center",
  fontWeight: 500,
  letterSpacing: "0.3px",
  opacity: 0.9,
  marginTop: theme.spacing(0.5),
}));

interface BirthdayCelebrationProps {
  birthDate?: string;
}

const BALLOON_COLORS = [
  [340, 85, 65], // Pink
  [280, 80, 60], // Purple
  [200, 80, 60], // Blue
  [145, 80, 65], // Green
  [35, 85, 60], // Orange
  [55, 85, 65], // Yellow
  [0, 85, 60], // Red
  [170, 80, 60], // Teal
] as const;

const STAR_COLORS = [
  "rgba(255, 223, 87, 0.8)", // Gold
  "rgba(255, 255, 255, 0.8)", // White
  "rgba(255, 182, 193, 0.8)", // Pink
  "rgba(173, 216, 230, 0.8)", // Light Blue
];

const isBirthday = (birthDate: string): boolean => {
  const today = new Date();
  const birth = new Date(birthDate);

  return (
    today.getUTCMonth() === birth.getUTCMonth() &&
    today.getUTCDate() === birth.getUTCDate()
  );
};

const hasShownCelebrationToday = (): boolean => {
  const today = new Date().toISOString().split("T")[0];
  const lastShown = localStorage.getItem("birthdayCelebrationShown");
  return lastShown === today;
};

const markCelebrationShown = () => {
  const today = new Date().toISOString().split("T")[0];
  localStorage.setItem("birthdayCelebrationShown", today);
};

interface StarStyle {
  key: string;
  style: {
    left: string;
    top: string;
    width: string;
    height: string;
    color: string;
    animationDuration: string;
    animationDelay: string;
    "--dx": string;
    "--dy": string;
  };
}

const generateStar = (index: number): StarStyle => {
  const size = 10 + Math.random() * 15;
  const color = STAR_COLORS[index % STAR_COLORS.length];
  const dx = (Math.random() - 0.5) * 100;
  const dy = (Math.random() - 0.5) * 100;
  const duration = 3 + Math.random() * 2;
  const delay = Math.random() * 2;

  return {
    key: `star-${index}`,
    style: {
      left: `${10 + Math.random() * 80}%`,
      top: `${10 + Math.random() * 80}%`,
      width: `${size}px`,
      height: `${size}px`,
      color,
      animationDuration: `${duration}s`,
      animationDelay: `${delay}s`,
      "--dx": `${dx}px`,
      "--dy": `${dy}px`,
    },
  };
};

const InteractiveBalloon = styled(Balloon)(() => ({
  pointerEvents: "auto",
  cursor: "pointer",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
  "&.popping": {
    animation: `${pop} 0.5s ease-out forwards`,
  },
  "&.flying-up": {
    animation: `${flyUp} 4s linear forwards`,
  },
  "&.flying-up-left": {
    animation: `${flyUpLeft} 4s linear forwards`,
  },
  "&.flying-up-right": {
    animation: `${flyUpRight} 4s linear forwards`,
  },
  "&.flying-up-zigzag": {
    animation: `${flyUpZigzag} 4s linear forwards`,
  },
  "&.flying-up-spiral": {
    animation: `${flyUpSpiral} 4s linear forwards`,
  },
  "&.flying-up-gentle": {
    animation: `${flyUpGentle} 4s linear forwards`,
  },
  "&.flying-up-wave": {
    animation: `${flyUpWave} 4s linear forwards`,
  },
  "&.flying-up-bounce": {
    animation: `${flyUpBounce} 4s linear forwards`,
  },
  "&.flying-up-dance": {
    animation: `${flyUpDance} 4s linear forwards`,
  },
  "&.flying-up-float": {
    animation: `${flyUpFloat} 4s linear forwards`,
  },
}));

const InteractiveStar = styled(Star)(() => ({
  pointerEvents: "auto",
  cursor: "pointer",
  "&:hover": {
    animation: `${sparkle} 0.5s ease-in-out`,
  },
}));

export const BirthdayCelebration = ({
  birthDate,
}: BirthdayCelebrationProps) => {
  const [showCelebration, setShowCelebration] = useState(false);
  const [activeBalloons, setActiveBalloons] = useState<Set<number>>(new Set());
  const [flyingBalloons, setFlyingBalloons] = useState<Map<number, string>>(
    new Map()
  );
  const [showMessage, setShowMessage] = useState(true);
  const [showStars, setShowStars] = useState(true);
  const cleanupRef = useRef<(() => void) | null>(null);

  const isMobile = useMemo(() => window.innerWidth < 768, []);
  const numBalloons = useMemo(() => (isMobile ? 8 : 12), [isMobile]);

  const flyingStyles = useMemo(
    () => [
      "flying-up",
      "flying-up-left",
      "flying-up-right",
      "flying-up-zigzag",
      "flying-up-spiral",
      "flying-up-gentle",
      "flying-up-wave",
      "flying-up-bounce",
      "flying-up-dance",
      "flying-up-float",
    ],
    []
  );

  useEffect(() => {
    if (!birthDate) {
      setShowCelebration(false);
      return;
    }

    const result = isBirthday(birthDate) && !hasShownCelebrationToday();
    setShowCelebration(result);

    if (result) {
      markCelebrationShown();
      const balloonsToFly = Array.from({ length: numBalloons }, (_, i) => i);
      setActiveBalloons(new Set(balloonsToFly));

      balloonsToFly.forEach((index) => {
        const randomStyle =
          flyingStyles[Math.floor(Math.random() * flyingStyles.length)];
        setFlyingBalloons((prev) => {
          const newMap = new Map(prev);
          newMap.set(index, randomStyle);
          return newMap;
        });

        setTimeout(() => {
          setActiveBalloons((prev) => {
            const newSet = new Set(prev);
            newSet.delete(index);
            return newSet;
          });
        }, 4000);
      });

      const messageTimer = setTimeout(() => {
        setShowMessage(false);
        setShowStars(false);
      }, 5000);

      cleanupRef.current = () => {
        setShowCelebration(false);
        clearTimeout(messageTimer);
      };
    }

    return () => {
      if (cleanupRef.current) {
        cleanupRef.current();
      }
    };
  }, [birthDate, numBalloons, flyingStyles]);

  const balloonStyles = useMemo(() => {
    return Array.from({ length: numBalloons }, (_, index) => {
      const baseSize = 60 + (index % 3) * 10;
      const [h, s, l] = BALLOON_COLORS[index % BALLOON_COLORS.length];
      const section = Math.floor(index / 3);
      const baseLeft = section * 25 + (index % 3) * 8;
      const top = 15 + (index % 4) * 20;

      return {
        left: `${baseLeft}%`,
        top: `${top}%`,
        width: `${baseSize}px`,
        height: `${baseSize * 1.3}px`,
        background: `linear-gradient(135deg, 
          hsla(${h}, ${s}%, ${l}%, 0.95) 0%,
          hsla(${h}, ${s - 5}%, ${Math.max(l - 25, 40)}%, 0.95) 100%)`,
        animationDelay: `${index * 0.3}s`,
        animationDuration: `${12 + (index % 5)}s`,
        zIndex: Math.floor(index / 4),
        filter: "drop-shadow(0 6px 15px rgba(0,0,0,0.15))",
        "&::before": {
          background: `linear-gradient(135deg,
            hsla(${h}, ${s}%, ${Math.max(l - 15, 35)}%, 0.95) 0%,
            hsla(${h}, ${s - 5}%, ${Math.max(l - 30, 30)}%, 0.95) 100%)`,
        },
      };
    });
  }, [numBalloons]);

  const stars = useMemo(
    () => Array.from({ length: 20 }, (_, index) => generateStar(index)),
    []
  );

  if (!showCelebration) return null;

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: "none",
        zIndex: 9999,
        overflow: "hidden",
        touchAction: "none",
        userSelect: "none",
        "& > *": {
          pointerEvents: "none",
          userSelect: "none",
          touchAction: "none",
        },
      }}
    >
      {showStars &&
        stars.map(({ key, style }) => (
          <InteractiveStar key={key} sx={style} data-testid="birthday-star" />
        ))}
      {showMessage && (
        <MessageContainer
          sx={{ pointerEvents: "none" }}
          className={!showMessage ? "fade-out" : ""}
          data-testid="birthday-message-container"
          aria-hidden="true"
        >
          <Box sx={{ textAlign: "center" }}>
            <Message variant="h5">🎉 Happy Birthday! 🎈</Message>
            <SubMessage variant="subtitle1">
              ✨ Wishing you an amazing day! ✨
            </SubMessage>
          </Box>
        </MessageContainer>
      )}
      {balloonStyles.map(
        (style, index) =>
          activeBalloons.has(index) && (
            <InteractiveBalloon
              key={`balloon-${index}`}
              sx={style}
              className={flyingBalloons.get(index) || ""}
              data-testid="birthday-balloon"
            />
          )
      )}
    </Box>
  );
};
