import { Box, Stack, StackProps } from "@mui/material";
import { useSearch, SearchState } from "@/hooks";
import { useCategories, useOffices } from "@/hooks/api";
import {
  toCategoryFilterOpts,
  toLocationFilterOpts,
  updateFilters,
} from "@/utils";
import { SearchField, Filter } from "@/components";
import { KeyValuePair } from "@/interface";
import { FiltersRenderer } from "./FiltersRenderer";
import { PropsWithChildren, useCallback } from "react";
import { Toggle } from "@/components/Filter/Toggle";

type Props = PropsWithChildren<
  {
    keywork?: boolean;
    category?: boolean;
    location?: boolean;
    search?: boolean;
    multipleCategories?: boolean;
    seekingContributorsToggle?: boolean;
  } & StackProps
>;

export const Filters = ({
  keywork = true,
  category = true,
  location = true,
  search = true,
  multipleCategories = true,
  seekingContributorsToggle = true,
  children,
  ...rest
}: Props) => {
  const { categories } = useCategories();
  const { data: locations } = useOffices();
  const {
    categories: filteredCategories,
    regions,
    offices,
    seekingContributors,
    setState,
  }: SearchState = useSearch();

  const handleKeywordChange = useCallback(
    (keyword: string) => {
      setState({ keyword });
    },
    [setState]
  );

  const onCategorySelect = (kv: KeyValuePair) => {
    if (multipleCategories) {
      setState({
        categories: updateFilters(kv, filteredCategories),
      });
    } else {
      setState({
        categories: updateFilters(kv, {}),
      });
    }
  };

  const onDelete = (kv: KeyValuePair) => {
    const updateState = (
      kv: KeyValuePair,
      stateKey: string,
      record: Record<string, string>
    ) => {
      if (record[kv.key]) {
        setState({
          [stateKey]: updateFilters(kv, record),
        });
      }
    };

    updateState(kv, "categories", filteredCategories || {});
    updateState(kv, "regions", regions || {});
    updateState(kv, "offices", offices || {});
  };

  const onClear = () => {
    setState({
      categories: {},
      regions: {},
      offices: {},
      keyword: "",
      seekingContributors: false,
    });
  };

  return (
    <Stack spacing={2} {...rest}>
      {keywork && (
        <Box display="flex" alignItems="center" gap={1}>
          {children}
          {children && search && (
            <SearchField
              setKeyword={handleKeywordChange}
              sx={{ ml: "auto", background: "transparent", width: 250 }}
            />
          )}
        </Box>
      )}
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
        {category && (
          <Filter
            label="Category"
            options={toCategoryFilterOpts(categories)}
            selected={{ ...filteredCategories }}
            multiple={multipleCategories}
            onSelectParent={onCategorySelect}
            onSelectChild={onCategorySelect}
            onSelect={onCategorySelect}
          />
        )}
        {location && (
          <Filter
            label="Location"
            selected={{ ...regions, ...offices }}
            options={toLocationFilterOpts(locations)}
            onSelectParent={(kv) =>
              setState({ regions: updateFilters(kv, regions) })
            }
            onSelectChild={(kv) =>
              setState({ offices: updateFilters(kv, offices) })
            }
            multiple
          />
        )}
        <FiltersRenderer
          filters={{ ...filteredCategories, ...regions, ...offices }}
          onDelete={onDelete}
          onClear={onClear}
        />
        {!children && search && (
          <SearchField
            setKeyword={handleKeywordChange}
            sx={{ ml: "auto", background: "transparent", width: 250 }}
          />
        )}
      </Box>
      {seekingContributorsToggle && (
        <Toggle
          label="Seeking contributors"
          onClick={() =>
            setState({ seekingContributors: !seekingContributors })
          }
          selected={seekingContributors}
        />
      )}
    </Stack>
  );
};
