/* eslint-disable */
import { useCallback, useEffect, useState } from "react";
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  debounce,
} from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

type Props = {
  setKeyword: (keyword: string) => void;
} & TextFieldProps;

export const SearchField = ({ setKeyword, ...rest }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [prevInputValue, setPrevInputValue] = useState("");

  // Debounce function to limit updates
  const debouncedSetState = useCallback(
    debounce((newKeyword) => setKeyword(newKeyword), 300),
    [setKeyword]
  );

  useEffect(() => {
    // Only call the debounced function if:
    // - Input value is longer than 2 characters
    // or
    // - previous value was not empty if inputValue was erased
    if (
      inputValue.length > 2 ||
      (prevInputValue.length > 0 && inputValue.length < 3)
    ) {
      debouncedSetState(inputValue);
    }

    return () => {
      debouncedSetState.clear();
    };
  }, [inputValue, debouncedSetState, prevInputValue]);

  return (
    <TextField
      size="small"
      value={inputValue}
      onChange={(e) => {
        setPrevInputValue(inputValue);
        setInputValue(e.target.value);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchRoundedIcon />
          </InputAdornment>
        ),
      }}
      placeholder="Search initiative"
      variant="outlined"
      {...rest}
    />
  );
};
