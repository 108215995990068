import { Box, Stack, styled, Typography } from "@mui/material";
import dayjs from "dayjs";
import DateRangeRoundedIcon from "@mui/icons-material/DateRangeRounded";

type Props = {
  startDate?: string;
  endDate?: string;
};

const StyledDateIcon = styled(DateRangeRoundedIcon)({
  marginRight: "10px",
});

const StyledBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const DateTitle = styled(Typography)({
  width: "50px",
});

export const Dates = ({ startDate, endDate }: Props) => {
  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "inline-flex",
          alignContent: "space-between",
        }}
      >
        <StyledDateIcon />
        <Typography variant="subtitle1">Dates</Typography>
      </Box>
      <Stack direction="row" alignItems="center">
        <StyledBox>
          <DateTitle>Start</DateTitle>
          <Typography>{dayjs(startDate).format("ll")}</Typography>
        </StyledBox>
      </Stack>
      {endDate && (
        <Stack direction="row" alignItems="center">
          <StyledBox>
            <DateTitle>End</DateTitle>
            <Typography>{dayjs(endDate).format("ll")}</Typography>
          </StyledBox>
        </Stack>
      )}
    </Stack>
  );
};
