import { PropsWithChildren } from "react";
import { Box, BoxProps, Typography, styled } from "@mui/material";
import { EditorFooter } from "./EditorFooter";
import { Editor, UserAvatar } from "@/components";
import { useUserState } from "@/state";
import { GifPreview } from "../../../components/GifPreview/GifPreview";

const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  boxShadow: theme.shadows[1],
}));

type Props = PropsWithChildren<
  {
    title?: string;
    element?: JSX.Element;
    initialContent?: string;
    caption?: string;
    onEditorChange: (html: string) => void;
    placeholder?: string;
    showFooter?: boolean;
    selectedGif?: string | null;
    onRemoveGif?: () => void;
  } & BoxProps
>;

export const ContributionEditor = ({
  title,
  element,
  initialContent,
  caption = "Add a contribution",
  placeholder = "Provide detailed info: Outline what you did, the results, time spent, and any team members involved.",
  onEditorChange,
  showFooter,
  children,
  selectedGif,
  onRemoveGif,
  ...rest
}: Props) => {
  const { userData } = useUserState();

  return (
    <StyledContainer {...rest}>
      <UserAvatar user={userData}>
        <Typography variant="subtitle2">{caption}</Typography>
        {title ? (
          <Typography variant="body2" color="text.secondary">
            in {title}
          </Typography>
        ) : (
          element
        )}
      </UserAvatar>
      {selectedGif && onRemoveGif && (
        <GifPreview src={selectedGif} onRemove={onRemoveGif} />
      )}
      <Editor
        initialContent={initialContent}
        placeholder={placeholder}
        onChange={onEditorChange}
      >
        <EditorFooter showFooter={showFooter}>{children}</EditorFooter>
      </Editor>
    </StyledContainer>
  );
};
