import ThemeProvider from "./theme";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import GettingStartedWizard from "./components/GettingStartedWizard";
import useGettingStartedWizard from "./hooks/useGettingStartedWizard";

const App = () => {
  const { isOpen, handleClose, currentStep, updateStep } =
    useGettingStartedWizard();

  return (
    <ThemeProvider>
      <RouterProvider router={routes} />
      <GettingStartedWizard
        open={isOpen}
        onClose={handleClose}
        currentStep={currentStep}
        updateStep={updateStep}
      />
    </ThemeProvider>
  );
};

export { App };
