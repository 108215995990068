import { ShoutoutData } from "@/interface";
import { ShoutRenderer } from "./ShoutRenderer";
import { useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { ShoutoutsSkeleton } from "./ShoutoutSkeleton";
import { useUserState } from "@/state";
import { Roles, ReactionType } from "@/enums";
import { DeleteShoutout } from "./common/DeleteShoutout";
import { ReportShoutout } from "./common/ReportShoutout";
import { EditShoutout } from "./edit/EditShoutout";
import { ReportSuccessModal } from "./common/ReportSuccessModal";

type Action = "delete" | "edit" | "report" | "report-success";

type Props = {
  shouts: ShoutoutData[];
  hasReactions?: boolean;
  loadMore: () => void;
  isLoading: boolean;
  onDelete: (shoutoutId: string) => void;
  onEdit: (shoutout: ShoutoutData) => void;
  onAddReaction?: (
    userId: string,
    shoutoutId: string,
    reactionType: ReactionType,
    fullName: string
  ) => void;
  onDeleteReaction: (
    userId: string,
    shoutoutId: string,
    reactionId: string,
    reactionType: ReactionType
  ) => void;
};

export const ShoutoutsList = ({
  shouts,
  isLoading,
  hasReactions = false,
  loadMore,
  onDelete,
  onEdit,
  onAddReaction,
  onDeleteReaction,
}: Props) => {
  const { userData } = useUserState();
  const [open, setOpen] = useState<Action>();
  const [shoutout, setShoutout] = useState<ShoutoutData>();

  const onAction = (action: Action, shoutout: ShoutoutData) => {
    setOpen(action);
    setShoutout(shoutout);
  };

  const isAdmin = userData?.roles?.includes(Roles.ADMIN);


  return (
    <>
      <Virtuoso
        useWindowScroll
        totalCount={shouts.length}
        endReached={loadMore}
        increaseViewportBy={100}
        itemContent={(index) => {
          const canEdit = shouts?.[index].author.id === userData?.id; // owner
          const fullName = userData?.firstName + ' ' + userData?.lastName;
          const canDelete = isAdmin || canEdit;

          return (
            <ShoutRenderer
              key={shouts[index].id}
              shout={shouts[index]}
              hasReactions={hasReactions}
              onEdit={
                canEdit ? () => onAction("edit", shouts[index]) : undefined
              }
              onDelete={
                canDelete ? () => onAction("delete", shouts[index]) : undefined
              }
              onReport={() => onAction("report", shouts[index])}
              onAddReaction={(type) =>
                onAddReaction?.(userData?.id ?? "", shouts[index].id, type, fullName)
              }
              onDeleteReaction={(reactionId, type) =>
                onDeleteReaction?.(
                  userData?.id ?? "",
                  shouts[index].id,
                  reactionId,
                  type
                )
              }
            />
          );
        }}
        components={{
          Footer: () => (isLoading ? <ShoutoutsSkeleton /> : null),
        }}
      />

      <DeleteShoutout
        open={open === "delete"}
        shoutout={shoutout}
        onClose={() => setOpen(undefined)}
        mutate={() => onDelete(shoutout?.id as string)}
      />

      <EditShoutout
        open={open === "edit"}
        shoutout={shoutout}
        onClose={() => setOpen(undefined)}
        mutate={onEdit}
      />

      <ReportShoutout
        open={open === "report"}
        shoutout={shoutout}
        onClose={() => {
          setOpen(undefined);
        }}
        onSuccess={() => {
          setOpen("report-success");
        }}
      />

      <ReportSuccessModal
        open={open === "report-success"}
        onClose={() => setOpen(undefined)}
      />
    </>
  );
};
