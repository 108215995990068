import { useMemo, useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Stack,
  Fade,
  Grow,
  IconButton,
  useMediaQuery,
  alpha,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { wizardSteps } from "@/config/wizardSteps";

interface GettingStartedWizardProps {
  open: boolean;
  onClose: (completed?: boolean) => void;
  currentStep: number;
  updateStep: (step: number) => void;
}

export default function GettingStartedWizard({
  open,
  onClose,
  currentStep,
  updateStep,
}: GettingStartedWizardProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const safeCurrentStep = Math.max(
    0,
    Math.min(currentStep, wizardSteps.length - 1)
  );

  const memoizedStepContent = useMemo(
    () => wizardSteps[safeCurrentStep],
    [safeCurrentStep]
  );

  const commonGradient = `linear-gradient(135deg, #6D53FF 0%, #7C3AED 100%)`;
  const softGradient = `linear-gradient(135deg, ${alpha("#6D53FF", 0.08)} 0%, ${alpha("#7C3AED", 0.12)} 100%)`;

  const styles = {
    button: {
      textTransform: "none" as const,
      minWidth: 130,
      borderRadius: "28px",
      py: 1.5,
      px: 3.5,
      fontSize: "0.875rem",
      fontWeight: 600,
      boxShadow: "0 4px 12px rgba(124, 58, 237, 0.15)",
      "&:hover": {
        boxShadow: "0 6px 16px rgba(124, 58, 237, 0.25)",
        transform: "translateY(-2px)",
      },
      "&:active": {
        transform: "translateY(0)",
      },
      transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    },
    background: {
      pattern: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.03,
        background: `
          radial-gradient(circle at 0% 0%, ${alpha("#6D53FF", 0.12)} 2px, transparent 2px) 0 0 / 60px 60px,
          radial-gradient(circle at 100% 100%, ${alpha("#7C3AED", 0.12)} 2px, transparent 2px) 30px 30px / 60px 60px
        `,
        pointerEvents: "none",
        zIndex: 0,
      },
      gradient: {
        background: `linear-gradient(180deg, 
          ${alpha(theme.palette.background.paper, 0.95)} 0%,
          ${alpha(theme.palette.background.paper, 0.98)} 100%
        )`,
      },
    },
    icon: {
      container: {
        p: { xs: 2.5, md: 3 },
        borderRadius: "50%",
        background: softGradient,
        transform: "scale(1.1)",
        transition: "all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1)",
        position: "relative",
        flexShrink: 0,
        animation: "float 5s ease-in-out infinite",
        "&::before": {
          content: '""',
          position: "absolute",
          inset: -1,
          background: commonGradient,
          borderRadius: "50%",
          opacity: 0.1,
          transition: "opacity 0.3s ease-in-out",
        },
        "&::after": {
          content: '""',
          position: "absolute",
          inset: -12,
          borderRadius: "50%",
          background: `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.08)} 0%, transparent 70%)`,
          opacity: 0,
          transition: "opacity 0.3s ease-in-out",
        },
        "&:hover": {
          transform: "scale(1.15) rotate(5deg)",
          "&::before": { opacity: 0.2 },
          "&::after": { opacity: 1 },
          "& > svg": {
            transform: "scale(1.1)",
            filter: "drop-shadow(0 4px 8px rgba(124, 58, 237, 0.3))",
          },
          "& .sparkle": { opacity: 1 },
        },
        "& > svg": {
          transition: "all 0.4s cubic-bezier(0.34, 1.56, 0.64, 1)",
          fontSize: { xs: 40, md: 48 },
        },
      },
      sparkle: {
        position: "absolute",
        width: "12px",
        height: "12px",
        background: commonGradient,
        borderRadius: "50%",
        opacity: 0,
      },
    },
    listItem: {
      backgroundColor: alpha(theme.palette.primary.main, 0.03),
      borderRadius: 2,
      py: 2,
      px: 2.5,
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      position: "relative",
      overflow: "hidden",
      backdropFilter: "blur(8px)",
      "&::before": {
        content: '""',
        position: "absolute",
        inset: 0,
        background: softGradient,
        opacity: 0,
        transition: "opacity 0.3s ease-in-out",
      },
      "&:hover": {
        backgroundColor: "transparent",
        transform: "translateX(4px) translateY(-2px)",
        boxShadow: `0 4px 16px ${alpha(theme.palette.primary.main, 0.15)}`,
        "&::before": { opacity: 1 },
        "& .MuiListItemIcon-root": {
          transform: "scale(1.1) rotate(5deg)",
        },
      },
    },
    chip: {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: "0.875rem",
      height: 36,
      px: 1,
      borderRadius: "18px",
      border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
      transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      "&:hover": {
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        transform: "translateY(-2px)",
        boxShadow: `0 4px 12px ${alpha(theme.palette.primary.main, 0.15)}`,
      },
    },
  };

  const handleNext = useCallback(() => {
    if (safeCurrentStep === wizardSteps.length - 1) {
      onClose(true);
    } else {
      updateStep(safeCurrentStep + 1);
    }
  }, [safeCurrentStep, onClose, updateStep]);

  const handleBack = useCallback(() => {
    updateStep(safeCurrentStep - 1);
  }, [safeCurrentStep, updateStep]);

  const handleSkip = useCallback(() => {
    onClose(true);
  }, [onClose]);

  const handleActionClick = useCallback(() => {
    const currentStepData = wizardSteps[safeCurrentStep];
    if (currentStepData?.route) {
      if (safeCurrentStep === wizardSteps.length - 1) {
        onClose(true);
      } else {
        onClose(false);
      }
      window.location.href = currentStepData.route;
    } else {
      handleNext();
    }
  }, [safeCurrentStep, onClose, handleNext]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!open) return;

      switch (event.key) {
        case "ArrowRight":
        case "Enter":
          if (safeCurrentStep < wizardSteps.length - 1) {
            handleNext();
          }
          break;
        case "ArrowLeft":
          if (safeCurrentStep > 0) {
            handleBack();
          }
          break;
        case "Escape":
          handleSkip();
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [open, safeCurrentStep, handleNext, handleBack, handleSkip]);

  const handleStepClick = useCallback(
    (step: number) => {
      updateStep(step);
    },
    [updateStep]
  );

  // Fix dialogStyles useMemo dependencies
  const dialogStyles = useMemo(
    () => ({
      "& .MuiDialog-container": {
        alignItems: "center",
        justifyContent: "center",
      },
      "& .MuiBackdrop-root": {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        transition: "opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      },
      "& .MuiDialog-paper": {
        borderRadius: { xs: 0, md: 3 },
        minHeight: { xs: "100vh", md: "auto" },
        maxWidth: { xs: "100%", md: "95%", lg: "1200px" },
        maxHeight: { xs: "100vh", md: "92vh" },
        margin: { xs: 0, md: 3 },
        background: theme.palette.background.paper,
        boxShadow: "0 16px 48px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        position: "relative",
        "&::before": styles.background.pattern,
        "@media (min-width: 600px)": {
          "&:hover": {
            transform: "scale(1.005)",
          },
        },
      },
    }),
    [theme.palette.background.paper, styles.background.pattern]
  );

  // Fix stepperStyles useMemo dependencies
  const stepperStyles = useMemo(
    () => ({
      mt: 1,
      "& .MuiStepLabel-root": {
        flexDirection: "column",
        "& .MuiStepLabel-iconContainer": {
          padding: 0,
          mb: 0.5,
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            top: -4,
            left: -4,
            right: -4,
            bottom: -4,
            borderRadius: "50%",
            background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.main, 0.05)} 100%)`,
            opacity: 0,
            transform: "scale(0.8)",
            transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          },
        },
        "&:hover .MuiStepLabel-iconContainer::after": {
          opacity: 1,
          transform: "scale(1)",
        },
      },
      "& .MuiStepLabel-root .Mui-completed": {
        color: theme.palette.primary.main,
      },
      "& .MuiStepLabel-root .Mui-active": {
        color: theme.palette.primary.main,
      },
      "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
        fill: "#fff",
        fontWeight: 600,
      },
      "& .MuiStepConnector-line": {
        borderColor: alpha(theme.palette.primary.main, 0.12),
        borderWidth: 2,
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
      },
      "& .MuiStepIcon-root": {
        width: 36,
        height: 36,
        transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
        "&.Mui-active": {
          transform: "scale(1.2)",
          filter: "drop-shadow(0 4px 8px rgba(124, 58, 237, 0.25))",
        },
      },
      "& .MuiStepLabel-label": {
        fontSize: "0.875rem",
        fontWeight: 500,
        marginTop: 0.5,
        transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
        "&.Mui-active": {
          color: theme.palette.primary.main,
          fontWeight: 600,
          transform: "scale(1.05)",
        },
      },
    }),
    [theme.palette.primary.main]
  );

  // Fix contentPaperStyles useMemo dependencies
  const contentPaperStyles = useMemo(
    () => ({
      p: { xs: 3, sm: 4 },
      mt: 3,
      backgroundColor: alpha(theme.palette.background.paper, 0.6),
      backdropFilter: "blur(20px)",
      borderRadius: 3,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      position: "relative",
      overflow: "hidden",
      border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
      boxShadow: `0 8px 32px ${alpha(theme.palette.primary.main, 0.08)}`,
      transition: "all 0.3s ease-in-out",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: "3px",
        background: commonGradient,
      },
    }),
    [theme.palette.primary.main, theme.palette.background.paper, commonGradient]
  );

  return (
    <Dialog
      open={open}
      maxWidth={false}
      fullScreen={fullScreen}
      onClose={() => onClose()}
      sx={dialogStyles}
      TransitionComponent={Grow}
      TransitionProps={{ timeout: 300 }}
      PaperProps={{
        elevation: 24,
      }}
    >
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          background: styles.background.gradient,
        }}
      >
        <DialogTitle
          sx={{
            pb: 1,
            pt: 3,
            px: { xs: 3, sm: 4 },
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "80%",
              height: "1px",
              background: `linear-gradient(90deg, 
                transparent 0%,
                ${alpha(theme.palette.primary.main, 0.1)} 50%,
                transparent 100%
              )`,
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 800,
                background: commonGradient,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontSize: { xs: "1.5rem", sm: "2rem" },
                letterSpacing: "-0.02em",
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  bottom: -4,
                  left: 0,
                  width: "60%",
                  height: "3px",
                  background: commonGradient,
                  borderRadius: "4px",
                },
              }}
            >
              Xconnect Guide
            </Typography>
            <IconButton
              onClick={() => onClose()}
              aria-label="Close wizard"
              sx={{
                color: "text.secondary",
                p: 1,
                backdropFilter: "blur(8px)",
                backgroundColor: alpha(theme.palette.background.paper, 0.6),
                "&:hover": {
                  color: theme.palette.primary.main,
                  transform: "rotate(90deg)",
                  backgroundColor: alpha(theme.palette.primary.main, 0.08),
                },
                transition: "all 0.3s ease-in-out",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent
          sx={{
            p: { xs: 3, sm: 4 },
            overflow: "auto",
            background: styles.background.gradient,
          }}
        >
          <Stepper
            activeStep={safeCurrentStep}
            sx={{
              ...stepperStyles,
              mb: 4,
              mx: { xs: -1, sm: 0 },
              p: 2,
              borderRadius: 2,
              backgroundColor: alpha(theme.palette.primary.main, 0.03),
              border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
              "& .MuiStepLabel-root": {
                ...stepperStyles["& .MuiStepLabel-root"],
                px: { xs: 1, sm: 2 },
              },
            }}
            alternativeLabel
            nonLinear
          >
            {wizardSteps.map((step, index) => (
              <Step key={step.title} completed={index < safeCurrentStep}>
                <StepButton
                  onClick={() => handleStepClick(index)}
                  disabled={index > safeCurrentStep + 1}
                  sx={{
                    cursor:
                      index <= safeCurrentStep + 1 ? "pointer" : "not-allowed",
                  }}
                >
                  <StepLabel
                    optional={
                      <Typography
                        variant="caption"
                        sx={{
                          opacity: 0.7,
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        Step {index + 1} of {wizardSteps.length}
                      </Typography>
                    }
                  >
                    {step.shortTitle || step.title}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>

          <Box
            sx={{
              maxHeight: { xs: "calc(100vh - 320px)", md: "65vh" },
              overflow: "auto",
              scrollBehavior: "smooth",
              mx: "auto",
              width: "100%",
              maxWidth: "960px",
              px: { xs: 0, sm: 2 },
              "&::-webkit-scrollbar": {
                width: "8px",
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: alpha(theme.palette.primary.main, 0.2),
                borderRadius: "8px",
                "&:hover": {
                  background: alpha(theme.palette.primary.main, 0.3),
                },
              },
            }}
          >
            <Fade in={true} timeout={300}>
              <Paper elevation={0} sx={contentPaperStyles}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 3, md: 4 }}
                  alignItems={{ xs: "center", md: "flex-start" }}
                  sx={{
                    textAlign: { xs: "center", md: "left" },
                    pb: { xs: 2, md: 3 },
                    position: "relative",
                  }}
                >
                  {memoizedStepContent?.icon && (
                    <Box sx={styles.icon.container}>
                      {memoizedStepContent.icon}
                      {[0, 1, 2].map((i) => (
                        <Box
                          key={i}
                          className="sparkle"
                          sx={{
                            ...styles.icon.sparkle,
                            "@keyframes sparkle": {
                              "0%": {
                                opacity: 0,
                                transform: "scale(0) rotate(0deg)",
                              },
                              "50%": {
                                opacity: 1,
                                transform: "scale(1) rotate(180deg)",
                              },
                              "100%": {
                                opacity: 0,
                                transform: "scale(0) rotate(360deg)",
                              },
                            },
                            animation: "sparkle 1.5s ease-in-out infinite",
                            animationDelay: `${i * 0.5}s`,
                            top: `${20 + i * 30}%`,
                            left: `${80 + i * 10}%`,
                          }}
                        />
                      ))}
                    </Box>
                  )}

                  <Stack spacing={2} sx={{ flex: 1, minWidth: 0 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 700,
                        fontSize: { xs: "1.5rem", sm: "1.75rem", md: "2rem" },
                        color: theme.palette.text.primary,
                        letterSpacing: "-0.02em",
                        lineHeight: 1.2,
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      {safeCurrentStep === 0
                        ? "Start Your Journey"
                        : memoizedStepContent?.title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 500,
                        fontSize: {
                          xs: "1.125rem",
                          sm: "1.25rem",
                          md: "1.375rem",
                        },
                        background: commonGradient,
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        letterSpacing: "-0.01em",
                        lineHeight: 1.4,
                        mb: 1,
                      }}
                    >
                      {memoizedStepContent?.subtitle}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: alpha(theme.palette.text.primary, 0.8),
                        lineHeight: 1.7,
                        fontSize: { xs: "1rem", sm: "1.125rem" },
                        letterSpacing: "0.01em",
                      }}
                    >
                      {memoizedStepContent?.content}
                    </Typography>
                  </Stack>
                </Stack>

                {memoizedStepContent?.features && (
                  <List
                    sx={{
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
                      gap: { xs: 2, md: 3 },
                      mt: 2,
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    {memoizedStepContent.features.map((feature, index) => (
                      <Grow in key={feature} timeout={400 + index * 100}>
                        <ListItem sx={styles.listItem}>
                          <ListItemIcon
                            sx={{
                              minWidth: 36,
                              mr: 2,
                              transition:
                                "transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)",
                              "& svg": {
                                fontSize: 24,
                                filter:
                                  "drop-shadow(0 2px 4px rgba(124, 58, 237, 0.2))",
                              },
                            }}
                          >
                            <CheckCircleOutlineIcon color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={feature}
                            primaryTypographyProps={{
                              fontSize: { xs: "0.9375rem", sm: "1rem" },
                              fontWeight: 500,
                              letterSpacing: "0.01em",
                              lineHeight: 1.5,
                            }}
                          />
                        </ListItem>
                      </Grow>
                    ))}
                  </List>
                )}

                {memoizedStepContent?.chips && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 1.5,
                      width: "100%",
                      justifyContent: { xs: "center", md: "flex-start" },
                    }}
                  >
                    {memoizedStepContent.chips.map((chip, index) => (
                      <Grow in key={chip} timeout={400 + index * 100}>
                        <Chip label={chip} sx={styles.chip} />
                      </Grow>
                    ))}
                  </Box>
                )}
              </Paper>
            </Fade>
          </Box>
        </DialogContent>

        <DialogActions
          sx={{
            p: { xs: 3, sm: 4 },
            pt: { xs: 2, sm: 3 },
            background: `linear-gradient(180deg, 
              ${alpha(theme.palette.background.paper, 0.8)} 0%,
              ${theme.palette.background.paper} 100%
            )`,
            backdropFilter: "blur(10px)",
            borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "4px",
              background: `linear-gradient(90deg, 
                transparent 0%,
                ${alpha(theme.palette.primary.main, 0.1)} 50%,
                transparent 100%
              )`,
            },
          }}
        >
          <Button
            onClick={handleSkip}
            sx={{
              ...styles.button,
              color: "text.secondary",
              fontSize: "0.875rem",
              minWidth: "auto",
              px: 2.5,
              "&:hover": {
                color: "text.primary",
                backgroundColor: alpha(theme.palette.primary.main, 0.04),
              },
            }}
          >
            Skip Guide
          </Button>
          <Box sx={{ flex: 1 }} />
          <Stack direction="row" spacing={2}>
            {safeCurrentStep > 0 && (
              <Button
                onClick={handleBack}
                sx={{
                  ...styles.button,
                  color: "text.secondary",
                  fontSize: "0.875rem",
                  minWidth: "auto",
                  px: 2.5,
                  "&:hover": {
                    color: "text.primary",
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                  },
                }}
              >
                <KeyboardArrowLeftIcon sx={{ mr: 0.5, fontSize: 20 }} />
                Previous
              </Button>
            )}
            {memoizedStepContent?.actionLabel && (
              <Button
                onClick={handleActionClick}
                variant="outlined"
                sx={{
                  ...styles.button,
                  borderWidth: 1.5,
                  fontSize: "0.875rem",
                  px: 3,
                  "&:hover": {
                    borderWidth: 1.5,
                    backgroundColor: alpha(theme.palette.primary.main, 0.04),
                  },
                }}
              >
                {memoizedStepContent.actionLabel}
              </Button>
            )}
            <Button
              onClick={handleNext}
              variant="contained"
              endIcon={
                safeCurrentStep < wizardSteps.length - 1 && (
                  <KeyboardArrowRightIcon />
                )
              }
              sx={{
                ...styles.button,
                background: commonGradient,
                fontSize: "0.875rem",
                px: 3,
                "&:hover": {
                  background: commonGradient,
                  transform: "translateY(-1px)",
                  boxShadow: "0 6px 16px rgba(124, 58, 237, 0.3)",
                },
              }}
            >
              {safeCurrentStep === wizardSteps.length - 1
                ? "Get Started"
                : "Next"}
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
