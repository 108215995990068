import { useEffect, useState, useCallback } from "react";
import { useSparxState } from "@/state";

export function useGifMessage(
  resetMessage?: boolean,
  isRequiredField?: boolean
) {
  const { activity, setActivityMessage } = useSparxState();

  const [message, setMessage] = useState("");
  const [gifUrl, setGifUrl] = useState<string | null>(null);
  const [gifPickerOpen, setGifPickerOpen] = useState(false);
  const [error, setError] = useState(false);

  const shouldRender = !!activity && activity.transactions.length > 0;

  useEffect(() => {
    if (resetMessage) {
      setMessage("");
      setGifUrl(null);
      setActivityMessage("");
    }
  }, [resetMessage, setActivityMessage]);

  useEffect(() => {
    if (activity?.message) {
      const gifMatch = activity.message.match(
        /<img src="([^"]+)" alt="GIF" \/>/
      );
      if (gifMatch) {
        setGifUrl(gifMatch[1]);
        setMessage(
          activity.message.replace(/<img src="[^"]+" alt="GIF" \/>/, "")
        );
      } else {
        setMessage(activity.message);
        setGifUrl(null);
      }
    } else {
      setMessage("");
      setGifUrl(null);
    }
  }, [activity?.message]);

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    const updatedContent = gifUrl
      ? `<img src="${gifUrl}" alt="GIF" />${newMessage}`
      : newMessage;
    setActivityMessage(updatedContent);
  };

  const handleBlur = useCallback(() => {
    if (isRequiredField && !message.trim()) {
      setError(true);
    } else {
      setError(false);
    }
  }, [isRequiredField, message]);

  const handleGifSelect = (url: string) => {
    setGifUrl(url);
    const updatedContent = `<img src="${url}" alt="GIF" />${message}`;
    setActivityMessage(updatedContent);
    setGifPickerOpen(false);
  };

  const handleRemoveGif = () => {
    setGifUrl(null);
    setActivityMessage(message);
  };

  return {
    shouldRender,
    message,
    gifUrl,
    gifPickerOpen,
    setGifPickerOpen,
    handleMessageChange,
    handleGifSelect,
    handleRemoveGif,
    handleBlur,
    error,
  };
}
