import { Box, styled, Switch, Typography } from "@mui/material";

type Props = {
  selected: boolean;
  label: string;
  onClick: () => void;
};

const LabelText = styled(Typography)({
    marginLeft: "10px",
    cursor: "pointer",
  });


export const Toggle = ({ label, onClick, selected }: Props) => {
    return (
    <Box display="flex" onClick={onClick}>
      <Switch color="secondary" checked={selected} />
      <LabelText>{label}</LabelText>
    </Box>
  );
};
