import { Box, TextField, Typography, styled, Button } from "@mui/material";
import { Shout } from "./Shout";
import GifPicker from "../shoutouts/create/GifPicker";
import { useGifMessage } from "../../../hooks/useGifMessage";
import { GifPreview } from "@/components/GifPreview/GifPreview";


const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(1),
}));

const AddGifButton = styled(Button)({
  marginTop: "10px",
  alignSelf: "flex-start",
});

type Props = {
  resetMessage?: boolean;
  allowPostToShoutouts?: boolean;
  isRequiredField?: boolean;
};

export const LeaveMessage = ({
  resetMessage,
  allowPostToShoutouts = true,
  isRequiredField = false,
}: Props) => {
  const {
    shouldRender,
    message,
    gifUrl,
    gifPickerOpen,
    setGifPickerOpen,
    handleMessageChange,
    handleGifSelect,
    handleRemoveGif,
    handleBlur,
    error,
  } = useGifMessage(resetMessage, isRequiredField);

  if (!shouldRender) return null;

  return (
    <StyledBox>
      <Typography variant="subtitle2" mb={1}>
        Leave a message
      </Typography>
      {gifUrl && handleRemoveGif && (
        <GifPreview src={gifUrl} onRemove={handleRemoveGif} />
      )}
      <TextField
        required={isRequiredField}
        helperText={
          allowPostToShoutouts
            ? "Messages are hidden until the end of the quarter. For immediate visibility, post as a shout-out."
            : "*This field is required. Describe how the delegated tokens should be used."
        }
        value={message}
        onChange={handleMessageChange}
        onBlur={handleBlur}
        error={isRequiredField && error}
        multiline
        rows={3}
        variant="outlined"
      />
      <AddGifButton variant="outlined" onClick={() => setGifPickerOpen(true)}>
        Add GIF
      </AddGifButton>
      {allowPostToShoutouts && <Shout />}
      <GifPicker
        open={gifPickerOpen}
        onClose={() => setGifPickerOpen(false)}
        onSelect={handleGifSelect}
      />
    </StyledBox>
  );
};
