import { useMemo, useState, useEffect } from "react";
import { Autocomplete, Box, Chip, TextField, debounce } from "@mui/material";
import { TransactionType, UserData } from "@/interface";
import { useUsers } from "@/hooks/api";
import { useSparxState, useUserState } from "@/state";
import { UserAvatar, UserInfo } from "@/components";

type Props = {
  type?: TransactionType;
};

export const UserAutocomplete = ({ type }: Props) => {
  const { userData } = useUserState();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<UserData[]>([]);
  const [value, setValue] = useState<UserData[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [noOptionsText, setNoOptionsText] = useState(
    "Start typing a name or email"
  );
  const { setReceivers, activity, setReset } = useSparxState();
  const { data, isLoading } = useUsers({
    value: inputValue,
    role: type === "DELEGATION" ? "delegation_receiver" : "token_receiver",
    origin: "sparx",
  });

  useEffect(() => {
    setOptions(data ?? []);

    if (inputValue && data?.length === 0) {
      setNoOptionsText("User not found");
    } else {
      setNoOptionsText("Start typing a name or email");
    }
  }, [data, inputValue]);

  useEffect(() => {
    setValue(activity?.transactions.map((tr) => tr.receiver) ?? []);
  }, [activity]);

  useEffect(() => {
    if (activity?.transactions.length === 0) {
      setReset();
    }
  }, [activity, setReset]);

  const debouncedSetState = useMemo(
    () =>
      debounce((newInputValue: string) => setInputValue(newInputValue), 300),
    [setInputValue]
  );

  /**
   * The optionLabel must include the inputValue string in order for the option to be displayed.
   * In this case, inputValue can be a name or email so the optionLabel must include all those values.
   */
  const getOptionLabel = (option: UserData) =>
    `${option.lastName}, ${option.firstName}, ${option.email}`;

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_: any, receivers) => {
        setReceivers(receivers, type);
      }}
      size="small"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={getOptionLabel ?? ""}
      options={options}
      loading={open && isLoading}
      noOptionsText={noOptionsText}
      onInputChange={(_, newInputValue) => {
        debouncedSetState(newInputValue);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
          key={option.id}
        >
          <UserAvatar user={option}>
            <UserInfo user={option} />
          </UserAvatar>
        </Box>
      )}
      //disabling self-inclusion
      getOptionDisabled={(option) => option.id === userData?.id}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              label={option.fullName}
              size="small"
              color="secondary"
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Recipient(s)"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
          helperText={
            type !== "DELEGATION"
              ? "Select recipients that you want to reward with Sparx"
              : ""
          }
        />
      )}
    />
  );
};
