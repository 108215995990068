import CelebrationRoundedIcon from "@mui/icons-material/CelebrationRounded";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import LightbulbRoundedIcon from "@mui/icons-material/LightbulbRounded";
import MonitorHeartRoundedIcon from "@mui/icons-material/MonitorHeartRounded";
import RedeemRoundedIcon from "@mui/icons-material/RedeemRounded";
import ManageHistoryRoundedIcon from "@mui/icons-material/ManageHistoryRounded";
import CreateIcon from "@mui/icons-material/Create";
import { MenuItem } from "../interface";
import { ROUTES } from "@/config";
import { useUserState } from "@/state";

const useMenuItems = () => {
  const { userData } = useUserState();
  const isTokenManager = userData?.roles?.includes("TOKEN_MANAGER");

  const menuItems: MenuItem[] = [
    {
      label: "Shout-outs",
      icon: <CelebrationRoundedIcon />,
      to: ROUTES.root,
    },
    {
      label: "Sparx",
      icon: <AutoAwesomeRoundedIcon />,
      to: ROUTES.sparx,
    },
    {
      label: "Initiatives",
      icon: <LightbulbRoundedIcon />,
      to: ROUTES.initiatives,
    },
    {
      label: "Contributions",
      icon: <CreateIcon />,
      to: ROUTES.contributions,
    },
    {
      label: "My Activity",
      icon: <MonitorHeartRoundedIcon />,
      to: ROUTES.my_activity,
    },
    {
      label: "Rewards",
      icon: <RedeemRoundedIcon />,
      to: ROUTES.rewards,
    },
  ];

  // Conditionally add the Token Management menu item
  if (isTokenManager) {
    menuItems.push({
      label: "Operations",
      icon: <ManageHistoryRoundedIcon />,
      to: ROUTES.operations,
    });
  }

  return menuItems;
};

export { useMenuItems };
