import { useState, useEffect } from "react";
import { useUserState } from "../state";

type LocalStorageKey = "x-connect-wizard-completed" | "x-connect-wizard-step";

const STORAGE_KEYS = {
  WIZARD_COMPLETED: "x-connect-wizard-completed" as LocalStorageKey,
  WIZARD_CURRENT_STEP: "x-connect-wizard-step" as LocalStorageKey,
} as const;

const STORAGE_VALUES = {
  COMPLETED: "true",
} as const;

const getStorageItem = (key: LocalStorageKey): string | null => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error(`Error reading from localStorage: ${error}`);
    return null;
  }
};

const setStorageItem = (key: LocalStorageKey, value: string): void => {
  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(`Error writing to localStorage: ${error}`);
  }
};

const removeStorageItem = (key: LocalStorageKey): void => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing from localStorage: ${error}`);
  }
};

export const useGettingStartedWizard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { authToken, refreshToken, userData } = useUserState();

  useEffect(() => {
    // Only proceed if user is authenticated and we have user data
    if (!authToken || !refreshToken || !userData) {
      return;
    }

    const navigationEntry = performance.getEntriesByType(
      "navigation"
    )[0] as PerformanceNavigationTiming;
    const hasCompletedWizard = getStorageItem(STORAGE_KEYS.WIZARD_COMPLETED);
    const savedStep = getStorageItem(STORAGE_KEYS.WIZARD_CURRENT_STEP);

    // Check if this is the user's first ever visit by comparing lastAccess with createdAt
    const isFirstEverVisit = userData.lastAccess === userData.createdAt;

    // Show wizard on first ever visit or page refresh
    if (
      !hasCompletedWizard &&
      (navigationEntry.type === "reload" || isFirstEverVisit)
    ) {
      setIsOpen(true);
      if (savedStep) {
        const parsedStep = parseInt(savedStep, 10);
        if (!isNaN(parsedStep)) {
          setCurrentStep(parsedStep);
        }
      }
    }
  }, [authToken, refreshToken, userData]); // Re-run effect when auth state or user data changes

  const handleClose = (completed = false) => {
    setIsOpen(false);
    if (completed) {
      setStorageItem(STORAGE_KEYS.WIZARD_COMPLETED, STORAGE_VALUES.COMPLETED);
      removeStorageItem(STORAGE_KEYS.WIZARD_CURRENT_STEP);
    }
  };

  const updateStep = (step: number) => {
    setCurrentStep(step);
    setStorageItem(STORAGE_KEYS.WIZARD_CURRENT_STEP, step.toString());
  };

  const resetWizard = () => {
    removeStorageItem(STORAGE_KEYS.WIZARD_COMPLETED);
    removeStorageItem(STORAGE_KEYS.WIZARD_CURRENT_STEP);
    setCurrentStep(0);
    setIsOpen(true);
  };

  return {
    isOpen,
    currentStep,
    handleClose,
    updateStep,
    resetWizard,
  };
};

export default useGettingStartedWizard;
