import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Collapse,
  Dialog,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { ReviewsRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { ROUTES } from "@/config";
import { OverflowMenu, UserAvatar, UserInfo } from "@/components";
import { ExtendedTransaction } from "@/interface";
import { SparxTransactionEnum } from "@/enums";
import { useUserState, useSparxState } from "@/state";
import { formatDateLongMonth } from "@/utils";
import { DeleteDialog } from "../../common/DeleteDialog";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  backgroundColor: theme.palette.secondary.main,
  border: "solid white 2px",
}));

type Props = {
  transaction: ExtendedTransaction;
  hideEditButton?: boolean;
  mutate: (() => void) | undefined;
};

export const TransactionItem = ({
  transaction,
  hideEditButton = false,
  mutate,
}: Props) => {
  const { userData } = useUserState();
  const [openExpandableMessage, setOpenExpandableMessage] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openOverflowMenu = Boolean(anchorEl);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { setTransaction } = useSparxState();
  const userIsRecipient = transaction.receiver.id === userData?.id;

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setTransaction(transaction.id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onEdit = () =>
    navigate(`${ROUTES.edit_sparx}?id=${transaction.activity.id}`);

  const onDelete = () => {
    setOpenDeleteDialog(true);
  };

  return (
    <>
      <TableRow sx={{ display: "table-row" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenExpandableMessage(!openExpandableMessage)}
            disabled={!transaction.activity.message}
            sx={{
              visibility: transaction.activity.message ? "unset" : "hidden",
            }}
          >
            {openExpandableMessage ? (
              <KeyboardArrowDownRoundedIcon />
            ) : (
              <KeyboardArrowRightRoundedIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1} alignItems="center">
            <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
              badgeContent={
                <StyledAvatar
                  sx={(theme) => ({ backgroundColor: theme.palette.grey.A200 })}
                >
                  <ReviewsRounded sx={{ width: 12, height: 12 }} />
                </StyledAvatar>
              }
              invisible={!transaction.activity.message}
              sx={(theme) => ({ color: theme.palette.grey.A200 })}
            >
              <UserAvatar user={transaction.displayedUser} />
            </Badge>
            <UserInfo user={transaction.displayedUser} />
          </Stack>
        </TableCell>
        <TableCell align="right">
          <Stack alignItems="flex-end">
            <Typography
              variant="subtitle1"
              sx={
                userIsRecipient
                  ? {
                      color: theme.palette.customColors.transactionAmountText,
                      backgroundColor:
                        theme.palette.customColors.transactionAmountBackground,
                      borderRadius: 1,
                      px: 1,
                      width: "fit-content",
                    }
                  : null
              }
            >
              {transaction.amount}
            </Typography>
            <Typography variant="body2">
              {transaction.type === SparxTransactionEnum.DELEGATION
                ? "Delegated"
                : userIsRecipient
                  ? "Received"
                  : "Sent"}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="right">
          {formatDateLongMonth(new Date(transaction.createdAt))}
        </TableCell>
        <TableCell align="right">
          <OverflowMenu
            open={openOverflowMenu}
            anchorEl={anchorEl}
            deleteCaption="Revert transaction"
            hideEditButton={hideEditButton}
            setOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </TableCell>
      </TableRow>
      <TableRow
        sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.divider}` })}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={openExpandableMessage} timeout="auto" unmountOnExit>
            <Box sx={{ marginLeft: "10%", marginY: 2 }}>
              <Typography variant="subtitle2" gutterBottom component="div">
                Message:
              </Typography>
              <Typography variant="body2">
                {transaction.activity.message}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        maxWidth="sm"
        open={openDeleteDialog}
        fullScreen={fullScreen}
        onClose={() => setOpenDeleteDialog(false)}
        sx={{
          "& .MuiDialog-paperFullScreen": {
            borderRadius: theme.spacing(0),
          },
        }}
      >
        <DeleteDialog
          onClose={() => {
            setOpenDeleteDialog(false);
            setAnchorEl(null);
            mutate ? mutate() : null;
          }}
          sparxAmount={transaction.amount}
        />
      </Dialog>
    </>
  );
};
